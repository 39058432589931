// export type WssClientOptions = {
//   onMessage: (message: any) => void;
//   onError: (error: string) => void;
//   onConnect: () => void;
//   onDisconnect: () => void;
// };

export function createWssClient(
  url,//: string,
  token,//: string,
  options,//: WssClientOptions,
  reconnectInterval,//: number = 5000 // Default reconnect interval is 5 seconds
) {
  let reconnectTimeout = null/*: NodeJS.Timeout | null = null*/;
  let socket = null/*: WebSocket | null = null*/;

  const connectWebSocket = () => {
    socket = new WebSocket(url/*, ['Authorization', `Bearer ${token}`]*/);

    socket.onopen = () => {
      console.log('wssClient: Connected to WebSocket');
      options.onConnect();
    };

    socket.onclose = () => {
      console.log('wssClient: Disconnected from WebSocket');
      options.onDisconnect();
      reconnectTimeout = setTimeout(connectWebSocket, reconnectInterval);
    };

    socket.onmessage = (event) => {
      console.log("wssClient: onMessage", event.data);
      options.onMessage(event.data);
    };

    socket.onerror = (error) => {
      console.error('wssClient: WebSocket error:', error);
      options.onError('WebSocket connection error');
      reconnectTimeout = setTimeout(connectWebSocket, reconnectInterval);
    };

    return () => {
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
      }
      socket.close();
    };
  };

  // Initial connection attempt
  connectWebSocket();

  return {
    reconnect: connectWebSocket,
    sendMessage: (message/*: string*/) => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(message);
      } else {
        console.error('wssClient: WebSocket is not open. Cannot send message.');
      }
    }
  };
}
