import React, { useEffect, useState, useRef } from 'react';
import { fin } from "@openfin/core";

const IabBlotter = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [updatedIndices, setUpdatedIndices] = useState([]);
  const [messageCount, setMessageCount] = useState(0);
  const channelName = 'wssIabChannel';
  const channelRef = useRef(null);

  useEffect(() => {
    const identity = {
        uuid:`ID-${new Date().getTime()}`,
        name: "IabBlotter"
    };

    const createIabChannel = async () => {
      try {
        const channel = await fin.InterApplicationBus.Channel.connect(channelName);
        channel.register('onWssMessage', handleWssMessage);
        channelRef.current = channel;
        setIsConnected(true);
        
        //subscribe to wss messages
        channel.dispatch("wssSubscribe", identity)
      } catch (error) {
        setError(error.message);
      }
    };

    if (!channelRef.current) {
      createIabChannel();
    }

    return () => {
      if (channelRef.current) {
        channelRef.current.unregister('onWssMessage');
        channelRef.current = null;
      }
    };
  }, []);

  const handleWssMessage = (payload) => {
    console.log(payload);
    const parsedData = JSON.parse(payload);
    if(parsedData.items == null)
    {
      return;
    }
    const updatedItems = parsedData.items.filter((item) => item.symbol !== undefined);
    updateData(updatedItems);
    setMessageCount((prevCount) => prevCount + 1);
  };

  const updateData = (updatedItems) => {
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedItems.forEach((item) => {
        const index = updatedData.findIndex((dataItem) => dataItem.symbol === item.symbol);
        if (index === -1) {
          updatedData.push(item);
        } else {
          updatedData[index] = item;
          setUpdatedIndices((prevIndices) => [...prevIndices, index]);
          setTimeout(() => {
            setUpdatedIndices((prevIndices) => prevIndices.filter((idx) => idx !== index));
          }, 300);
        }
      });
      return updatedData;
    });
  };

  const renderTableHeader = () => {
    if (data.length === 0) return null;
    return (
      <div className="row header">
        {Object.keys(data[0]).map((field) => (
          <div className="cell header-cell" key={field}>
            {field}
          </div>
        ))}
      </div>
    );
  };

  const renderTableRows = () => {
    return data.map((item, index) => (
      <div className={`row${updatedIndices.includes(index) ? ' updated' : ''}`} key={index}>
        {Object.values(item).map((value, valueIndex) => (
          <div className="cell" key={valueIndex}>
            {value}
          </div>
        ))}
      </div>
    ));
  };

  return (
    <div>
      <h1>IAB Notifications</h1>
      <p>Connection Status: {isConnected ? 'Connected' : 'Disconnected'}</p>
      <p>Message Count: {messageCount}</p>
      {error && <p>Error: {error}</p>}
      <div className="blotter">
        {renderTableHeader()}
        {renderTableRows()}
      </div>
    </div>
  );
};

export default IabBlotter;
