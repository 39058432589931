import React from "react";
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Blotter from "./components/blotter";
import IabBlotter from "./components/IabBlotter";
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Routes>
            <Route path="/blotter" element = {<Blotter/>} />
            <Route path="/iabblotter" element ={<IabBlotter/>} />
            <Route path="/" element ={<Blotter/>} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
