import React, { useEffect, useState } from 'react';
import { createWssClient } from './wss-client';

const Blotter = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [updatedIndices, setUpdatedIndices] = useState([]);
  const [messageCount, setMessageCount] = useState(0);

  useEffect(() => {
    const onMessage = (message) => {
      console.log(message);
      const parsedData = JSON.parse(message);
      if(parsedData.items == null)
      {
        return;
      }
      const updatedItems = parsedData.items.filter((item) => item.symbol !== undefined);

      setData((prevData) => {
        const updatedData = [...prevData];
        updatedItems.forEach((item) => {
          const index = updatedData.findIndex((dataItem) => dataItem.symbol === item.symbol);
          if (index === -1) {
            updatedData.push(item);
          } else {
            updatedData[index] = item;
            setUpdatedIndices((prevIndices) => [...prevIndices, index]);
            setTimeout(() => {
              setUpdatedIndices((prevIndices) => prevIndices.filter((idx) => idx !== index));
            }, 300);
          }
        });
        return updatedData;
      });

      setMessageCount((prevCount) => prevCount + 1);
    };

    const onError = (error) => {
      console.error('WebSocket error:', error);
      setError('WebSocket connection error');
      setIsConnected(false);
    };

    const onConnect = () => {
      console.log('Connected to WebSocket');
      setError(null);
      setIsConnected(true);
      wssClient.sendMessage(JSON.stringify({id: "12345", action: "subscribe",filters: ["quote:IBM","quote:AMZN","quote:MSFT",]}));
    };

    const onDisconnect = () => {
      console.log('Disconnected from WebSocket');
      setError('WebSocket connection closed');
      setIsConnected(false);
    };

    const apiUrl = 'wss://bpr6r75tqh.execute-api.us-east-1.amazonaws.com/DEV';
    const token = 'placeholder_token'; // Add token if necessary

    const wssClient = createWssClient(apiUrl, token, {
      onMessage,
      onError,
      onConnect,
      onDisconnect,
    });


  }, []);

  return (
    <div>
      <h1>WebSocket Notifications</h1>
      <p>Connection Status: {isConnected ? 'Connected' : 'Disconnected'}</p>
      <p>Message Count: {messageCount}</p>
      {error && <p>Error: {error}</p>}
      <div className="blotter">
        <div className="row header">
          {data.length > 0 &&
            Object.keys(data[0]).map((field) => (
              <div className="cell header-cell" key={field}>
                {field}
              </div>
            ))}
        </div>
        {data.map((item, index) => (
          <div
            className={`row${updatedIndices.includes(index) ? ' updated' : ''}`}
            key={index}
          >
            {Object.values(item).map((value, valueIndex) => (
              <div className="cell" key={valueIndex}>
                {value}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blotter;
